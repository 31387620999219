import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import style from "./header.module.css"

export default () => (
    <StaticQuery
        query={graphql`
            query Header {
                site {
                    siteMetadata{
                        title
                    }
                }
            }
        `}
        render={data => (
            <header className={style.header}>
                <div className="logo">
                    <Link to="/">{data.site.siteMetadata.title}</Link>
                </div>
            </header>
        )}
    />
);