import React from "react";
import Header from "../components/Header/";
import Navigation from "../components/Navigation/";

export default () => (
    <div class="wrap">
        <Header />
        <Navigation />
    </div>
)
